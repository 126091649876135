import { PLACE_ORDER, BASE_URL, CCAENUE_URL, ACCESSCode } from "./types";
import { createaddtoAddress } from "./address";
import { setAlert } from "./alert";
import axios from "axios";

const addapiUrl = BASE_URL + "addorderdetail";

export const startplaceorder = (params) => {
  return (dispatch) => {
    dispatch(createaddtoAddress(params))
      .then((response) => {
        return axios
          .post(addapiUrl, params)
          .then((response) => {
            if (response.data.logstatus == "cash") {
              window.location.href =
                "/torfodevuk/order-confirm/" + response.data.ordernumber;
            }
            if (response.data.logstatus == "razorpay") {
              dispatch(startplaceordersuccess(response.data));
            }
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const startplaceordersuccess = (post) => {
  return {
    type: PLACE_ORDER,
    payload: post,
  };
};
