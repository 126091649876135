import React, {Component} from 'react';
import Header from '../components/Header';
import Alert from '../layout/Alert';
import Footer from '../components/Footer';
import {Helmet} from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";



const initialState =  {
name:'',
lastname:'',
email:'',
subject:'',
message:'',
nameError:'',
lastError:'',
emailError:'',
subError:'',
msgError:'',
captchaTokenError: "",

};


export default class Contactus extends Component {

constructor(props) {
    super(props);
    this.captchaRef = React.createRef();
    this.state = initialState;
  }


handleInputChange = event => {
const isCheckbox = event.target.type === "checkbox";
this.setState({
[event.target.name]: isCheckbox
? event.target.checked
: event.target.value
});
};


  handleCaptchaChange = (event) => {
    this.setState({
      captchaToken: event,
    });
  };


validate = () => {
let nameError = "";
let lastError = "";
let emailError = "";
let subError = "";
let msgError = "";
let captchaTokenError = "";

if (!this.state.name) {
nameError = "Name cannot be blank";
}

if (!this.state.lastname) {
lastError = "Lastname cannot be blank";
}

if (!this.state.email) {
emailError = "Email cannot be blank";
}

if(this.state.email){
let lastAtPos = this.state.email.lastIndexOf('@');
let lastDotPos = this.state.email.lastIndexOf('.');

if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
emailError = "Email is not valid.";
}
}


if (!this.state.subject) {
subError = "Subject cannot be blank.";
}

if (!this.state.message) {
msgError = "Message cannot be blank.";
}

if (!this.state.captchaToken) {
      captchaTokenError = "Verification is not correct!";
    }

if (nameError || lastError || emailError || subError || msgError || captchaTokenError) {
this.setState({nameError,lastError,emailError,subError,msgError,captchaTokenError });
return false;
}
return true;
};

handleSubmit = e => {
e.preventDefault();
const isValid = this.validate();
if (isValid) {
this.props.onAddContact(this.state);
window.scrollTo(0, 0);
this.setState(initialState);
this.captchaRef.reset();

}
};

componentDidMount() {
window.scrollTo(0, 0);

}



render(){


const { loading } = this.props.createcontact;



return (
<div> 

<Helmet>
<meta charSet="utf-8" />
<title>Contact The Organic Forest</title>
<meta name="description" content="We are listening:306-307, SSR Corporate Park, 306 & 307, Mathura Rd, Ekta Nagar, Faridabad, Haryana 121003.Emailus:care@theorganicforest.com." />
<meta name="Keywords" content="Contact Us"/>
</Helmet>
<Header/>
<div class="conact-section section pt-60 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50">
<div class="container">
<div className="row">
<div className="col-12">
<div className="shop-banner-title">
<h2 className="leafbg contacsec" style={{'fontWeight':'600','color':'#db9c28','textAlign':'center'}}>Contact Us</h2>
</div>
</div>
</div>
<div class="row  mb-3">
<div class="col-md-6 offset-md-3 text-center">
<div class="contact-information">
<h3 style={{'fontWeight':'600'}}>Contact Information</h3>
<div className="col-10 mb-5 mx-auto text-center"> 
{/* <p className="font-size30"><span class="icon"><i class="pe-7s-call"></i></span></p>
<p>(+44) 20 38079675</p> */}
<p className="font-size30"><span class="icon"><i class="pe-7s-mail-open"></i></span></p>
<p>care@theorganicforest.com</p>
<p className="font-size30">

<a href="https://www.facebook.com/The-Organic-Forest-113438947068234" target={"_blank"}  className="facebook"><i className="fa fa-facebook"></i></a>
<a href="https://www.instagram.com/the_organic_forest" target={"_blank"} className="linkedin"><i className="fa fa-instagram pl-3"></i></a>
</p>

</div>

</div>
</div>
</div>
</div>


<div class="col-md-12" style={{'borderBottom':'1px solid'}}>


</div> 



<div class="container">

<div class="row">

<div class="col-md-10 offset-md-1 mt-3 text-center">
<div class="contact-form-wrap">
<Alert />
<h3 class="contact-title" style={{'fontWeight':'600'}}>Drop us a line</h3>
<form  method="post" onSubmit={ this.handleSubmit }>
<div class="row">
<div class="col-md-6">
<div class="contact-form-style mb-20" style={{'textAlign':'left'}}>
<input name="name" onChange={ this.handleInputChange } value={ this.state.name } placeholder="First Name*" type="text"/>
<div className='warning-msg' style={{'textAlign':'left'}}>{this.state.nameError}</div>

</div>
</div>
<div class="col-md-6">
<div class="contact-form-style mb-20" style={{'tetAlign':'left'}}>
<input name="lastname" onChange={ this.handleInputChange } value= { this.state.lastname } placeholder="Last Name*" type="text"/>
<div className='warning-msg' style={{'textAlign':'left'}}>{this.state.lastError}</div>

</div>
</div>
<div class="col-md-6">
<div class="contact-form-style mb-20" style={{'tetAlign':'left'}}>
<input name="email" onChange={ this.handleInputChange } value={ this.state.email } placeholder="Email*" type="text"/>
<div className='warning-msg' style={{'textAlign':'left'}}>{this.state.emailError}</div>
</div>
</div>
<div class="col-md-6">
<div class="contact-form-style mb-20" style={{'tetAlign':'left'}}>
<input name="subject" onChange={ this.handleInputChange } value={ this.state.subject } placeholder="Subject*" type="text"/>
<div className='warning-msg' style={{'textAlign':'left'}}>{this.state.subError}</div>

</div>
</div>
<div class="col-md-12">
<div class="contact-form-style mb-20" style={{'tetAlign':'left'}}>
<textarea name="message" onChange={ this.handleInputChange } value={ this.state.message } placeholder="Type your message here.."></textarea>
<div className='warning-msg' style={{'textAlign':'left'}}>{this.state.msgError}</div>











</div>
</div>

<div class="col-md-12">
<div class="contact-form-style mb-20" style={{'tetAlign':'left'}}>
<ReCAPTCHA sitekey="6Leen5cjAAAAAJTvOIVJSLGojXVQacPM7MKsPmUD" ref={(el) => {this.captchaRef = el}} onChange={this.handleCaptchaChange} />
<div className='warning-msg' style={{'textAlign':'left'}}> {this.state.captchaTokenError}</div></div></div>


<div class="col-md-12">
<div class="contact-form-style mb-20">

<button class="btn descount-sticker_btn" disabled={loading}>{loading && <span>Sending...</span>}
{!loading && <span>Send Message</span>}</button>
</div>
</div>


</div>
</form>                           
</div>
</div>
</div> </div>



</div>
<Footer/>

</div>


)
}
}







