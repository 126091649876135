import React from 'react';
import { connect } from 'react-redux';
import { updateAccount } from '../actions/accountinfo';
import Alert from '../layout/Alert';


const initialState =  {
    name:'',
    lastname:'',
    phone:'',
    gender:'',
    nameError:'',
    lastnameError:'',
    phoneError:'',
   };

var phoneRegex = /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/;


class AccountInfo extends React.Component {

constructor(props) {
    super(props);
    this.state = {
    name: this.nameInput ? this.nameInput.value: null,
    lastname :this.lastnameInput ? this.lastnameInput.value: null,
    phone: this.phoneInput ? this.phoneInput.value: null,
    gender :this.props.accountinfo ? this.props.accountinfo.gender : null,
    whatsapp_preference:this.props.accountinfo ? this.props.accountinfo.whatsapp_preference : null,
    sms_preference:this.props.accountinfo ? this.props.accountinfo.sms_preference : null,
    email_preference:this.props.accountinfo ? this.props.accountinfo.email_preference : null,
    
  };
  }



 validateUSPhoneNumber = (phoneNumber) => {
 // Regular expression for validating US phone numbers
    const phoneRegex = /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}(?:\s?x\d{1,5})?$/;
    
    return phoneRegex.test(phoneNumber);


};


  


validate = () => {


    let nameError = "";
    let lastnameError="";
    let phoneError="";
 

    if (!this.nameInput.value) {
       nameError = "Name cannot be blank";
    }
    if (!this.lastnameInput.value) {
       lastnameError = "Last Name cannot be blank";
    }
    if (!this.phoneInput.value) {
       phoneError = "Phone cannot be blank";
    }
    
    else if (!this.validateUSPhoneNumber(this.phoneInput.value)) {
      phoneError = "Please enter valid Phone Number";
    } 


    
   
   if (nameError  || lastnameError || phoneError ) {
      this.setState({nameError,lastnameError,phoneError});
      return false;
    }
return true;
  };



  handleChange = (event) => {
  const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = (event) => {                                                             
    event.preventDefault();
    const isValid = this.validate();
 
    if (isValid) {
    const emailprefer = this.state.email_preference === false?'0':'1';
    const smsprefer = this.state.sms_preference === false?'0':'1';
    const whatsprefer= this.state.whatsapp_preference === false?'0':'1';
    const name = this.state.name ? this.state.name : this.props.accountinfo.name;
    const lastname = this.state.lastname ? this.state.lastname : this.props.accountinfo.lastname;
    const phone = this.state.phone ? this.state.phone : this.props.accountinfo.phone;
    const gender = this.state.gender ? this.state.gender : this.props.accountinfo.gender;
    const whatsapp_preference = whatsprefer ? whatsprefer: this.props.accountinfo.whatsapp_preference;
    const sms_preference = smsprefer ? smsprefer: this.props.accountinfo.sms_preference;
    const email_preference = emailprefer ? emailprefer : this.props.accountinfo.email_preference;
    const updatedprofile = {name: name,lastname:lastname,phone:phone,gender:gender,email_preference:email_preference,sms_preference:sms_preference,whatsapp_preference:whatsapp_preference};
    this.props.onAddAccount(updatedprofile);
    this.setState(initialState);
  }
  };
render() {


const {accountinfo}= this.props;





     return (
      <div class="tab-content" id="myaccountContent">
      <div className="col-lg-8 col-12">
      <Alert msgdivid="infoupdate"/>
      </div>
                    <div class="tab-pane fade show active" id="account-info" role="tabpanel">
                        <div class="myaccount-contentab">
                            <h3>My Info </h3>
                            <div class="account-details-form">
                                <form onSubmit={this.handleSubmit}>
                                    <div class="row">
                                    <div className="col-lg-8 col-12">
                                    <div class="col-12 mb-30" style={{'paddingLeft':'0px'}}>
                                    <input id="first-name" name="name"  defaultValue= {accountinfo.name} onChange={this.handleChange}  placeholder="First Name" type="text" ref={element => this.nameInput = element} />
                                    <div className='warning-msg'>{this.state.nameError}</div>
                                    </div>
                                    <div class="col-12 mb-30" style={{'paddingLeft':'0px'}}>
                                    <input id="last-name" name="lastname" defaultValue= {accountinfo.lastname} onChange={this.handleChange}  placeholder="Last Name" type="text" ref={element => this.lastnameInput = element}/>
                                    <div className='warning-msg'>{this.state.lastnameError}</div>
                                    </div>
                                    <div class="col-12 mb-30" style={{'paddingLeft':'0px'}}>
                                    <input id="phone" name="phone"  maxlength="20"  defaultValue= {accountinfo.phone} onChange={this.handleChange}  placeholder="Phone No." type="text" ref={element => this.phoneInput = element}/>
                                    <div className='warning-msg'>{this.state.phoneError}</div>
                                    </div>
                                     <div class="col-12 mb-30" style={{'paddingLeft':'0px','display':'none'}}>
                                    <input id="gender" name="gender" defaultValue= {accountinfo.gender} onChange={this.handleChange}  placeholder="Gender" type="text" ref={element => this.genderInput = element}/>
                                    </div>
                                    <div class="col-12 mb-30" style={{'paddingLeft':'0px'}}>
                                    <input id="email" name="email" defaultValue= {accountinfo.email} onChange={this.handleChange}  placeholder="Email Address" type="text" readOnly/>
                                    </div>

                                    <label for="loginInputPassword">I'd like to receive exclusive discounts and news from The Organic Forest by:</label>
                                    <div className="form-group">                                          
                                        <input type="checkbox" name="email_preference" id="email_preference" defaultChecked= {accountinfo.email_preference} onClick={this.handleChange}  className="" style={{'width':'30px','display':'inline-block'}} />
                                        <label for="status">Email</label>
                                        <br />                                    
                                        <input type="checkbox" name="sms_preference" id="sms_preference" defaultChecked= {accountinfo.sms_preference} onClick={this.handleChange} className="" style={{'width':'30px','display':'inline-block'}} />
                                        <label for="status">SMS</label>
                                       <br />                                  
                                       <input type="checkbox" name="whatsapp_preference" id="whatsapp_preference" defaultChecked= {accountinfo.whatsapp_preference} onClick={this.handleChange} className="" style={{'width':'30px','display':'inline-block'}} />
                                        <label for="status">Whatsapp</label>
                                        <br />
                                    </div>

                                        <div className="form-group" style={{'paddingLeft':'0px'}}>
                                            <button class="btn btn-lg btn-round descount-sticker_btn">Save Changes</button>
                                        </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        )
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onAddAccount: post => {

      dispatch(updateAccount(post));
       
    }

  };
};


export default connect(
  null,
  mapDispatchToProps
)(AccountInfo);